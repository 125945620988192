import React from 'react'
import {ScreenSmallIcon} from '@xolvio/xolvio-ui'

export const pageData = {
  header: {
    text: "Careers at Xolvio",
    highlights: "Careers",
    body: "Join our mission to eliminate the problematic & mundane so the interesting can flourish.",
  },
  aboutUs: {
    smallHeader: 'ABOUT US',
    title: 'Boutique, collaborative company culture',
    body: 'While we’re a remote-only company, we embrace a flat hierarchy that fosters collaboration. This means you’ll be working with a breadth of people that have loads of expertise in many different areas. We also love open source, so feel free to check out our repositories!',
    ctaUrl: 'https://github.com/xolvio',
    ctaText: 'Find us on GitHub',
  },
  whyWorkWithUsList: {
    header: "Why work with us",
    icon: <ScreenSmallIcon />,
    list: [
      "Our company motto is “eliminating the problematic and mundane, so the interesting can flourish.” We found that the best way to do this is to focus on high quality and automation. This way we’re not bogged down with bugs and repetitive tasks so that we can focus on the most interesting work.",
      "Because of our flat hierarchy you get direct access to the company hive mind and our experience working on lots of different, interesting projects, from hardware to high-resilience, high-scale distributed systems.",
      "We're always looking at new technologies and techniques that we can leverage and use. We love to explore new things, keep an eye on the exciting world of Web3 technologies, and consider being progressive to be more than just a label.",
      "Do you enjoy pushing boundaries? Do you think outside the box? Do you thrive in the unknown? If yes then you’ll definitely enjoy great autonomy working on exciting projects at Xolvio, so don’t hesitate to reach out to us!",
    ]
  },
  openPositions: {
    subheader: "WORK AT XOLVIO",
    header: "Open Positions",
  }
};
