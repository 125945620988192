import React from "react";
import { LayoutDefault } from "../components/LayoutDefault";
import Posts from "../components/careers/Posts/Posts";
import {
  ButtonPrimaryLink,
  CenteredContentWrapper,
  device,
  EmphasizedText,
  ListChecked,
  SectionHeaderSmall,
  Subheading,
  TextBody,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  ConfettiHR,
} from '@xolvio/xolvio-ui'
import styled from "styled-components";
import { spacing } from "@xolvio/xolvio-ui";
import { pageData } from "../pages-content/careers";
import {ToutContent, ToutHeader, ToutTextBody, ToutWrapper} from '../components/Services'
import {
  TwoColumnSectionHeader
} from '../components/Services/ServicePageTemplate'
import {SectionHeader} from '@xolvio/xolvio-ui/src'
import {BlogInsightsSection} from '../components/BlogInsightsSection'

export default () => (
  <LayoutDefault>

    <ToutWrapper>
      <ToutContent>
        <ToutHeader>
          <EmphasizedText
            text={pageData.header.text}
            highlight={pageData.header.highlights}
          />
        </ToutHeader>
        <ToutTextBody style={{textAlign: "center"}}>{pageData.header.body}</ToutTextBody>
      </ToutContent>
    </ToutWrapper>

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <E2eImageWrapper>
          <img src={"/assets/images/end-to-end-delivery.svg"} width="500px" />
        </E2eImageWrapper>
      }
      contentNode={
        <StyledTwoColumnContentNodeWrapper>
          <StyledSubheading>{pageData.aboutUs.smallHeader}</StyledSubheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight="aaaaaaaaa"
              text={pageData.aboutUs.title}
            />
          </TwoColumnSectionHeader>
          <Text>{pageData.aboutUs.body}</Text>
          <ButtonPrimaryLink href={pageData.aboutUs.ctaUrl} as={"a"} target="_blank" rel="noopener noreferrer">
            <TextBody color={"white"} style={{ padding: 0, margin: "0 auto" }}>
              {pageData.aboutUs.ctaText}
            </TextBody>
          </ButtonPrimaryLink>
        </StyledTwoColumnContentNodeWrapper>
      }
    />

    <CenteredContentWrapper>
      <>
        <ListWrapperOuter>
          {pageData.whyWorkWithUsList.icon}
          <ListWrapperInner>
            <SectionHeaderSmall>{pageData.whyWorkWithUsList.header}</SectionHeaderSmall>
            <ListChecked>{pageData.whyWorkWithUsList.list}</ListChecked>
          </ListWrapperInner>
        </ListWrapperOuter>
      </>
    </CenteredContentWrapper>

    <PostsWrapper>
      <StyledSubheading>{pageData.openPositions.subheader}</StyledSubheading>
      <StyledSectionHeader>{pageData.openPositions.header}</StyledSectionHeader>
      <Posts/>
    </PostsWrapper>

    <BlogInsightsSection />

    <ConfettiHR />
  </LayoutDefault>
);

const StyledSubheading = styled(Subheading)`
  margin-bottom: 20px !important;
`

const StyledSectionHeader = styled(SectionHeader)`
  margin-top: 0;
`

const StyledTwoColumnContentNodeWrapper = styled(TwoColumnContentNodeWrapper)`
  @media ${device.mobile} {
    margin: 50px 0;
  }
`

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

const E2eImageWrapper = styled.div`
    @media ${device.mobile} {
      img {
        width: 100%; 
        margin-bottom: 50px;
      }
    }
`

const ListWrapperOuter = styled.div`
  display: flex;
  margin: 0;

  > svg {
    flex-shrink: 0;
    margin-top: 16px;
    margin-right: ${spacing.mobile.padding.default}px;
  }
`;

const ListWrapperInner = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    margin-top: -10px;
  }

  li {
    margin-left: -5px;
  }

  @media ${device.mobile} {
    > * {
      text-align: left;
    }
  }

  @media ${device.tablet} {
    h1 {
      margin-top: 15px;
    }
  }
`;

export const PostsWrapper = styled(CenteredContentWrapper)`
  margin: 80px 30px;
`;
